import React from 'react'
import styles from './Home.module.css'
import Navbar from '../../components/Navbar/Navbar'
import Catalog from '../../components/Catalog/Catalog'

function Home() {
    return (
        <>
            <Navbar />
            <main className={styles.main__container}>
                <Catalog />
            </main>
        </>
    )
}

export default Home