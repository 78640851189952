import { Routes, Route } from 'react-router-dom'
import { adminRoutes, publicRoutes } from '../../routes';
import { useSelector } from 'react-redux';

function AppRouter() {

    const user = useSelector(state => state.user);

    if (!user) {
        return
    }

    return (
        <Routes>

            {user.role === 'user' && publicRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}

            {user.role === 'admin' && adminRoutes.map(({ path, Component }) => (
                <Route key={path} path={path} element={<Component />} />
            ))}

        </Routes>
    )
}

export default AppRouter