import React from 'react'
import styles from './Catalog.module.css'
import CatalogItems from '../CatalogItem/CatalogItems'

function Catalog() {
    return (
        <section className={styles.catalog}>

            <h2 className={styles.catalog__title}>Каталог</h2>

            <CatalogItems />

        </section>
    )
}

export default Catalog