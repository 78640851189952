import React, { useEffect, useState, useCallback } from 'react'
import Loader from '../Loader/Loader'
import styles from './CatalogItems.module.css'
import { motion } from "framer-motion";
import { getListElements } from '../../http/shopApi'
import { useDispatch, useSelector } from 'react-redux';
import ProductItem from '../ProductItem/ProductItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchItems } from '../../reducers/itemsReducer';
import { setUser } from '../../reducers/userReducer';

function CatalogItems() {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [totalCount, setTotalCount] = useState(0);
    const [modal, setModal] = useState({ id: null, open: false });
    const [firstRender, setFirstRender] = useState(true);
    const cashItems = useSelector(state => state.items);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(true);
    const [items, setItems] = useState([]);

    const user = useSelector(state => state.user);

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.08
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        },
    };

    useEffect(() => {
        setTimeout(() => {
            if (user.scrollPosition) {
                window.scrollTo({
                    top: user.scrollPosition,
                    behavior: 'instant'
                });
            }
        }, 0.0000001) // :)
    }, [user.scrollPosition]);

    useEffect(() => {
        if (cashItems.items.length > 0) {
            setItems(cashItems.items);
        }
    }, [cashItems.items]);

    const getList = useCallback(async () => {
        if (cashItems.items.length > 0) {
            setItems(cashItems.items);
            setFetching(false);
            setFirstRender(false);
            return
        }

        try {
            const data = await getListElements(page, limit);
            const { items, total_count } = data;

            setItems(prevItems => [...prevItems, ...items]);
            setPage(prevPage => prevPage + 1);
            setTotalCount(total_count);
            dispatch(fetchItems({ items }));
            setFirstRender(true);
        } finally {
            setFetching(false);
        }
    }, [page, limit, cashItems.items, dispatch]);

    useEffect(() => {
        if (fetching) {
            getList();
        }
    }, [fetching]);

    const scrollHandler = useCallback((e) => {
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 && items.length < totalCount && !fetching) {
            setFetching(true);
        }
    }, [items, totalCount, fetching]);

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler);

        return () => {
            document.removeEventListener('scroll', scrollHandler);
        };
    }, [scrollHandler]);

    const handleNavigate = (el, index) => {
        dispatch(setUser({ ...user, scrollPosition: window.scrollY }));
        navigate(`/product/${index._id}`);
    };

    if (items.length === 0) {
        return (
            <div className={styles.loader}>
                <Loader />
            </div>
        )
    }

    return (
        <article className={styles.catalog__items}>
            <motion.ul
                className={styles.items}
                variants={container}
                initial={firstRender ? 'hidden' : 'visible'}
                animate="visible"
            >
                {items.map((index, idx) => (
                    <motion.li
                        key={index._id}
                        className={styles.item}
                        variants={item}
                        onClick={(el) => handleNavigate(el, index)}>

                        <figure className={styles.img__wrapper}>
                            {index.count === 0 ? <span className={styles.item__out}>Нет в наличии!</span> :
                                <img className={styles.item__img} src={process.env.REACT_APP_IMAGE_URL + index.image} alt={''} />}
                        </figure>

                        <div className={styles.item__info}>
                            <p className={styles.item__name}>{index.name}</p>
                            <div className={styles.item__btns}>
                                <button className={`${styles.item__btn} ${index.count === 0 && styles.count__out}`}>Купить за {index.price} {window.innerWidth > 380 ? '₽' : '₽'}</button>
                            </div>
                        </div>

                    </motion.li>
                ))}
            </motion.ul>

            {modal.open && <ProductItem id={modal.id} open={modal.open} setModal={setModal} />}
        </article >
    )
}

export default CatalogItems