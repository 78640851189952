const defaultState = {
    cart: [],
    delivery_address: "",
    delivery_method: "",
    email: "",
    id: 0,
    order_description: "",
    phone_number: "",
    username: "",
    role: "",
    scrollPosition: 0
}

export default function userReducer(state = defaultState, action) {

    switch (action.type) {
        case 'SET_USER':

            const { cart, delivery_address, delivery_method, email, id, order_description, phone_number, username, role, scrollPosition } = action.payload

            return {
                ...state,
                cart,
                delivery_address,
                delivery_method,
                email,
                order_description,
                id,
                phone_number,
                username,
                role,
                scrollPosition
            }

        default:
            return state
    }
}

export const setUser = info => ({ type: 'SET_USER', payload: info });