import React from 'react';
import styles from './FullScreenNotification.module.css';

const FullScreenNotification = ({ message, onConfirm, onCancel, confirmText = "Да", cancelText = "Нет", onlyConfirm = false }) => {
    return (
        <div className={styles.notificationWrapper}>
            <div className={styles.notification}>
                <p className={styles.message}>{message}</p>
                <div className={styles.buttons}>
                    <button onClick={onConfirm} className={styles.button}>{confirmText}</button>
                    {!onlyConfirm && (
                        <button onClick={onCancel} className={styles.button}>{cancelText}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FullScreenNotification;
