import React, { useEffect, useState } from 'react'
import styles from './ProductItem.module.css'
import { getElementById } from '../../http/shopApi';
import Loader from '../Loader/Loader';
import { FiPlus, FiMinus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { sendToCart } from '../../http/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { setUser } from '../../reducers/userReducer';
import { useNavigate, useParams } from 'react-router-dom';
import ChangeInfo from '../ChangeInfo/ChangeInfo';
import { v4 as uuidv4 } from 'uuid';

function ProductItem() {
    const { id } = useParams();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState();
    const [count, setCount] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);

    const [onePrice, setOnePrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        getListElement();
    }, [id]);

    useEffect(() => {
        const calculateTotalPrice = () => {
            let sum = parseFloat(item.price);

            selectedItems.forEach(el => {
                sum += parseFloat(el.option.price);
            });

            setOnePrice(sum);
            let totalPrice = sum * count;
            setTotalPrice(totalPrice);
        };

        if (item && selectedItems) {
            calculateTotalPrice();
        }
    }, [selectedItems, count, item]);


    const getListElement = async () => {
        setLoading(true);
        const data = await getElementById(id);
        setLoading(false);
        setItem(data);
    }

    const addToCart = async () => {
        const order = {
            id: uuidv4(),
            product_id: item._id,
            image: item.image,
            name: item.name,
            price: onePrice,
        };

        dispatch(setUser({ ...user, cart: [...user.cart, order] }));

        setLoading(true);
        await sendToCart(user.id, order);
        setLoading(false);
        navigate('/');
    }

    if (!item) {
        return (
            <div className={styles.loader}>
                <Loader />
            </div>
        )
    }

    return (
        <article className={styles.item}>

            <IoMdClose className={styles.item__close} onClick={() => navigate(-1)} />

            <figure className={styles.img__wrapper}>
                <img className={styles.item__img} src={process.env.REACT_APP_IMAGE_URL + item.image} alt={''} />
            </figure>

            <div className={styles.item__info}>

                <div className={`${styles.item__text}`}>
                    <h2 className={styles.item__name}>{item.name}</h2>
                    <p className={styles.item__price}>Цена: {item.price} руб.</p>
                    <p className={styles.item__description}>{item.description}</p>
                </div>

                {user.role === 'admin' && (
                    <ChangeInfo item={item} />
                )}

            </div>

            <div className={styles.item__counter}>

                <div className={styles.item__btns}>
                    {
                        user.cart.some(el => el.product_id === item._id) ? (
                            <button className={styles.no__btn} onClick={() => navigate('/cart')}>Уже в корзине</button>
                        ) : (
                            <motion.button
                                className={styles.item__btn}
                                onClick={addToCart}
                                whileTap={{ scale: 0.95 }}
                            >
                                Добавить
                                <div className={styles.item__price}>
                                    <span className={styles.item__sum}>{totalPrice.toFixed(2)} ₽</span>
                                </div>
                            </motion.button>
                        )
                    }

                </div>
            </div>

            {loading &&
                <div className={styles.loader}>
                    <Loader />
                </div>}
        </article >
    )
}

export default ProductItem