import React, { useState } from 'react'
import styles from './Payment.module.css'
import Navbar from '../../components/Navbar/Navbar';
import { sendOrder } from '../../http/userApi';
import { useDispatch, useSelector } from 'react-redux';
import BlockBody from '../../components/BlockBody/BlockBody';
import { setUser } from '../../reducers/userReducer';
import Loader from '../../components/Loader/Loader';
import { useTelegram } from '../../hooks/useTelegram';
import { motion } from "framer-motion";

function Payment({ openPaymentForm, setOpenPaymentForm, cart, form, setForm }) {

    const [loading, setLoading] = useState(false);
    const [touched, setTouched] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const { tg } = useTelegram();

    BlockBody({ open: openPaymentForm });

    const handleSendOrder = async () => {
        setTouched(true);
        const emailValid = validateEmail(form.email);
        setEmailError(!emailValid);

        if (form.full_name !== '' && form.phone !== '' && emailValid) {
            setLoading(true);
            const data = await sendOrder(form, cart);

            dispatch(setUser({ ...user, cart: [] }));
            window.open(data.confirmation_url, '_blank');
            setLoading(false);
            tg.close();
        }
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    }

    const openUrl = (url) => {
        window.open(process.env.REACT_APP_API_URL + '/api' + url, '_blank');
    }

    return (
        <section className={styles.payment__section}>

            <Navbar open={openPaymentForm} setOpen={setOpenPaymentForm} />

            <h2 className={styles.title}>Форма оплаты</h2>

            <div className={styles.payment}>

                <form className={styles.form}>

                    <div className={styles.field}>
                        <label className={styles.label}>ФИО*</label>
                        <input className={`${styles.input} ${touched && form.full_name === '' ? styles.error__input : ''}`}
                            placeholder='Иванов Иван Иванович'
                            value={form.full_name}
                            onChange={(e) => setForm({ ...form, full_name: e.target.value })} type="text" />
                        {touched && form.full_name === '' && <p className={styles.error}>Обязательное поле!</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Почта*</label>
                        <input className={`${styles.input} ${touched && (form.email === '' || emailError) ? styles.error__input : ''}`}
                            placeholder='ivanov@example.com'
                            value={form.email}
                            onChange={(e) => {
                                setForm({ ...form, email: e.target.value });
                                setEmailError(!validateEmail(e.target.value));
                            }} type="text" />
                        {touched && form.email === '' && <p className={styles.error}>Обязательное поле!</p>}
                        {touched && emailError && <p className={styles.error}>Неверный формат почты!</p>}
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Номер телефона*</label>
                        <input className={`${styles.input} ${touched && form.phone === '' ? styles.error__input : ''}`}
                            placeholder='+7 (999) 999-99-99'
                            value={form.phone}
                            onChange={(e) => setForm({ ...form, phone: e.target.value })} type="text" />
                        {touched && form.phone === '' && <p className={styles.error}>Обязательное поле!</p>}
                    </div>

                </form>

                <footer className={styles.footer}>
                    <motion.button className={styles.footer__btn} whileTap={{ scale: 0.95 }} onClick={() => handleSendOrder()}>Оформить заказ</motion.button>
                </footer>

                <footer className={styles.footer}>
                    <motion.button className={styles.footer__btn} whileTap={{ scale: 0.95 }} onClick={() => handleSendOrder()}>Оформить заказ</motion.button>
                    <p className={styles.rules}>Нажимая кнопку «Оформить заказ» вы соглашаетесь с условиями приобретения: 
                        <span className={styles.link} onClick={() => openUrl('/pdf2')}>Договор оферты, </span>
                        <span className={styles.link} onClick={() => openUrl('/pdf1')}>Политика обработки персональных данных, </span>
                        <span className={styles.link} onClick={() => openUrl('/pdf3')}>Согласие на обработку персональных данных.</span>
                    </p>
                </footer >

            </div>

            {loading &&
                <div className={styles.loader}>
                    <Loader />
                    <p className={styles.loader__text}>Идет загрузка...</p>
                </div>
            }

        </section>
    )
}

export default Payment
