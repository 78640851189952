import React, { useEffect, useState } from 'react';
import { deleteCartItem, fetchCart } from '../../http/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import styles from './Cart.module.css';
import { MdDeleteForever } from "react-icons/md";
import Loader from '../../components/Loader/Loader';
import Navbar from '../../components/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import ProductItem from '../../components/ProductItem/ProductItem';
import { setUser } from '../../reducers/userReducer';
import Payment from '../Payment/Payment';

function Cart() {
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const [openPaymentForm, setOpenPaymentForm] = useState(false);
    const [currentProduct, setCurrentProduct] = useState({ id: null, open: false });

    const [form, setForm] = useState({
        id: user.id,
        full_name: '',
        phone: '',
        email: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        getCart();
    }, [currentProduct]);

    const getCart = async () => {
        const data = await fetchCart(user.id);
        setCart(data.cart);
        setLoading(false);
    };

    const deleteFromCart = async (item) => {
        dispatch(setUser({ ...user, cart: cart.filter(el => el.cart_item_id !== item.cart_item_id) }));
        setCart(cart.filter(el => el.cart_item_id !== item.cart_item_id));
        await deleteCartItem(user.id, item.cart_item_id);
    };

    const openUrl = (url) => {
        window.open(process.env.REACT_APP_API_URL + '/api' + url, '_blank');
    }

    return (
        <>
            <Navbar />
            <h2 className={styles.title}>Корзина</h2>
            {loading ? <div className={styles.loader__wrapper}>
                <Loader />
            </div> : cart.length !== 0 ? (
                <motion.ul className={styles.cart} >
                    {cart.map((item) => (
                        <motion.li key={item._id} className={styles.item} >
                            <div className={styles.info}>
                                <figure className={styles.image__wrapper}>
                                    <img className={styles.image} src={process.env.REACT_APP_IMAGE_URL + item.image} alt="" />
                                </figure>
                                <div className={styles.description}>
                                    <p className={styles.name} onClick={() => navigate(`/product/${item._id}`)}>{item.name}</p>

                                    <div className={styles.price}>

                                        <p className={styles.price__total}>Цена за товар: {(item.price).toFixed(2)} ₽</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.button__delete}>
                                <MdDeleteForever className={styles.delete__icon} onClick={() => deleteFromCart(item)} />
                            </div>
                        </motion.li>
                    ))}
                    <li className={styles.empty}>Общая сумма: {cart.reduce((acc, item) => acc + item.price, 0).toFixed(2)} ₽</li>
                </motion.ul>
            ) : (
                <h3 className={styles.empty}>Корзина пуста</h3>
            )}
            {cart.length !== 0 && (
                <footer className={styles.footer}>
                    <motion.button className={styles.footer__btn} whileTap={{ scale: 0.95 }} onClick={() => setOpenPaymentForm(true)}>Оформить заказ</motion.button>
                    <p className={styles.rules}>Нажимая кнопку «Оформить заказ» вы соглашаетесь с условиями приобретения: 
                        <span className={styles.link} onClick={() => openUrl('/pdf2')}>Договор оферты, </span>
                        <span className={styles.link} onClick={() => openUrl('/pdf1')}>Политика обработки персональных данных, </span>
                        <span className={styles.link} onClick={() => openUrl('/pdf3')}>Согласие на обработку персональных данных.</span>
                    </p>
                </footer >
            )
            }

            {openPaymentForm && (
                <Payment cart={cart} openPaymentForm={openPaymentForm} setOpenPaymentForm={setOpenPaymentForm} form={form} setForm={setForm} />
            )}
            {currentProduct.open && <ProductItem id={currentProduct.id} open={currentProduct.open} setModal={setCurrentProduct} />}
        </>
    );
}

export default Cart;
