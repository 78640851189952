const initialState = {
    items: []
};

export default function itemsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_ITEMS_SUCCESS':
            const { items } = action.payload;
            return {
                ...state,
                items
            };
        case "UPDATE_ITEM":
            const updatedItems = state.items.map(item => {
                if (item._id === action.payload._id) {
                    return {
                        ...item,
                        ...action.payload,
                        image: action.payload.image ? action.payload.image : item.image
                    };
                }
                return item;
            });
            return { ...state, items: updatedItems };
        default:
            return state;
    }
}
export const fetchItems = info => ({ type: 'FETCH_ITEMS_SUCCESS', payload: info });
export const updateItem = item => ({ type: "UPDATE_ITEM", payload: item })