import React from 'react'
import { FaCartArrowDown } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import styles from './Navbar.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";
import { motion } from "framer-motion";
import { setUser } from '../../reducers/userReducer';

function Navbar({ open = null, setOpen = null }) {

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const handleNavigate = () => {
        dispatch(setUser({ ...user, scrollPosition: 0 }));
        navigate(-1);
    }

    return (
        <header className={styles.header}>
            <nav className={styles.navbar}>

                <div className={styles.cart__wrapper}>
                    {pathname === '/cart' || pathname === '/payment' ?
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ rotate: 360, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20
                            }}
                        >
                            <IoMdArrowBack className={styles.cart__icon} onClick={() => open ? setOpen(false) : handleNavigate()} />
                        </motion.div>
                        :
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ rotate: 360, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20
                            }}
                        >
                            <FaCartArrowDown className={`${styles.cart__icon} ${user.cart.length > 0 && styles.cart__green}`} onClick={() => navigate('/cart')} />
                        </motion.div>}
                </div>

                <div className={styles.profile}>
                    {user.role === 'admin' ?
                        <NavLink
                            className={styles.profile__link}
                            to="/admin-catalog"
                        >Админ панель</NavLink> : <p className={styles.profile__name}>{user.username}</p>}
                    <FaUserCircle className={styles.profile__icon} />
                </div>

            </nav>
        </header>
    )
}

export default Navbar