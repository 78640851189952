import Catalog from "./components/Catalog/Catalog"
import AdminCatalog from "./pages/AdminCatalog/AdminCatalog"
import Cart from "./pages/Cart/Cart"
import Home from "./pages/Home/Home"
import ProductItem from "./components/ProductItem/ProductItem"
import {
    HOME,
    CATALOG,
    PRODUCT,
    CART,
    ADMIN_CATALOG
} from "./utils/consts"

export const publicRoutes = [
    {
        path: HOME,
        Component: Home
    },
    {
        path: CATALOG,
        Component: Catalog
    },
    {
        path: PRODUCT,
        Component: ProductItem
    },
    {
        path: CART,
        Component: Cart
    }
]

export const adminRoutes = [
    {
        path: HOME,
        Component: Home
    },
    {
        path: CATALOG,
        Component: Catalog
    },
    {
        path: PRODUCT,
        Component: ProductItem
    },
    {
        path: CART,
        Component: Cart
    },
    {
        path: ADMIN_CATALOG,
        Component: AdminCatalog
    }
]