import { $host, $authHost } from "./index";

export const getListElements = async (page, limit) => {
    try {
        const { data } = await $host.get(`api/catalog`, {
            params: {
                page,
                limit
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getList:", error);
    }
}

export const getElementById = async (id) => {
    try {
        const { data } = await $host.get(`api/item`, {
            params: {
                id: id
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch getElementById:", error);
    }
}
