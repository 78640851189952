import { $host, $authHost } from "./index";

export const fetchProfile = async (id) => {
    try {
        const { data } = await $host.get(`api/profile`, {
            params: {
                user_id: id
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch profile:", error);
    }
}

export const sendToCart = async (userId, order) => {
    try {
        const { data } = await $host.post(`api/cart_add`, {
            user_id: userId,
            order: order
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch sendToCart:", error);
    }
}

export const fetchCart = async (id) => {
    try {
        const { data } = await $host.get(`api/cart`, {
            params: {
                user_id: id
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch fetchCart:", error);
    }
}

export const deleteCartItem = async (id, orderId) => {
    try {
        const { data } = await $host.post(`api/cart_remove`, {
            user_id: id,
            order_id: orderId
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch deleteCartItem:", error);
    }
}

export const sendOrder = async (form, cart) => {
    try {
        const { data } = await $host.post(`api/send_order`, {
            form,
            cart
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch sendOrder:", error);
    }
}

export const fetchChangeInfo = async (formData) => {
    try {
        const { data } = await $host.post(`api/change_info`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch fetchChangeInfo:", error);
    }
}

export const deleteInfo = async (userId, orderId) => {
    try {
        const { data } = await $host.post(`api/delete_info`, {
            user_id: userId,
            order_id: orderId
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch deleteInfo:", error);
    }
}

export const uploadFile = async (form) => {
    try {
        const { data } = await $host.post(`api/create_info`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch uploadFile:", error);
    }
};

export const updateCartItem = async (userId, id, count, price) => {
    try {
        const { data } = await $authHost.post(`api/cart_update`, {
            user_id: userId,
            order_id: id,
            price: price,
            count: count
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch updateCartItem:", error);
    }
}

export const fetchRelatedItems = async (relateds) => {
    try {
        const { data } = await $authHost.post(`api/add_dlc`, {
            relateds
        });
        return data;
    } catch (error) {
        console.error("Failed to fetch updateCartItem:", error);
    }
}