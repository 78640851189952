import { useEffect } from "react";


function BlockBody({ open }) {

    useEffect(() => {
        if (open) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [open]);

    return null

}

export default BlockBody